.custom-modal {
  border-radius: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.lg-modal-background {
  background-image: url("../../images/background-modal-medium.png");
  height: 630px;
}

@media (max-width: 450px) {
  .xs-modal-background {
    width: 100% !important;
    background-size: cover;
  }
}

.xs-modal-background {
  background-image: url("../../images/background-modal-small.png");
  height: 609px;
  /* width: 480px; */
  margin: 0 auto;
}

.xl-modal-background {
  background-image: url("../../images/background-modal-big.png");
  height: 698px;
}

.modal-content {
  border-radius: 24px;
  background-color: transparent;
  width: 100%;
  border: none !important;
}

.icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;

}

@media (max-width: 450px) {
  .icon-container {
    padding-right: 0px;
  }
}

.icon-close {
  font-size: 20px;
  color: #fff;
}

.button-close {
  background-color: #864bfa;
  border-radius: 50%;
  display: flex;
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-close:hover {
  background-color: #7340d8;
}

.medium-modal-size {
  width: 850px !important;
  height: 630px;
}