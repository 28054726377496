.search-container {
  border: 2px solid #D7C9EE;
  display: flex;
  border-radius: 30px;
  align-items: center;
  padding: 2px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.search-icon {
  color: #D7C9EE;
  font-size: 24px;
}

.search-input {
  border: none;
  color: #482481;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 132px;
}

.search-input:focus {
  outline: none;
  /* Elimina el borde de enfoque predeterminado en la mayoría de los navegadores */
  border: none;
}

.search-input::placeholder {
  color: #D7C9EE;
}

.search-icon-container {}