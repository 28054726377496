.secondary-button {
  border-radius: 200px;
  border: 3px solid var(--Stroke-Purple, #7340D8);
  background: #FFF;
  padding: 4px 18px;
  align-self: flex-end;
  color: #7340D8;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Mark Pro;
}

.secondary-button:hover {
  border: 3px solid var(--Stroke-Purple, #7340D8);

}

.secondary-button:disabled {
  border-radius: 200px;
  border: 3px solid var(--Light-Purple, #D7C9EE);
  background: var(--Ultra-Light-Purple, #D7C9EE);
}