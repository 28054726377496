.container-cotizador {
  background-image: url("../images/background-desktop.png");
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
}

.logo-auta {
  width: 124px;
  height: 60px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal-success {
  padding: 20px;
  border-radius: 16px;
  overflow: auto;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.background-modal-stopper {
  width: 682px;
  height: 375px;
  background-image: url("../images/background-stopper-desktop.png");
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-title-modal-stopper {
  color: var(--purple);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-description-modal-stopper {
  color: var(--purple);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-footer-modal-stopper {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-description-modal-stopper-mobile {
  display: none;
}

.text-error {
  color: #f40000;
  font-size: 12px;
  font-weight: 500;
}

.text-wait {
  color: var(--purple);
  font-size: 26px;
  font-weight: 600;
  text-align: center;
}

.card-contact-data-size {
  width: 343px;
  height: 153px;
}
.text-down-input {
  color: var(--dark-purple);
  font-family: Mark Pro;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 4px;
}

.card-contact-data {
  border-radius: 16px;
  border: 2px solid #d7c9ee;
  background: var(--white, #fff);
  padding: 10px;
  margin: 0 4rem 1rem 4rem;
}

.cotizador-desktop {
  height: 100%;
}

.cotizador-mobile {
  display: none;
}

.card-contact-header {
  border-radius: 8px;
  background-color: #864bfa;
  padding: 12px 32px;
}

.contact-header-title {
  color: var(--white, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.phone-number {
  color: #482481;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 8px;
}

.phone-number-two {
  color: #482481;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 8px;
  text-align: center;
}

.security-code-text {
  color: #864bfa;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin: 50px 0px !important; */
}

.input-labels {
  color: #482481;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.code-input {
  height: 40px;
  width: 40px;
  border-radius: 200px;
  border: 2px solid #d7c9ee;
  padding: 10px;
  text-align: center;
  color: var(--Purple, #864bfa);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.code-input:focus {
  border: 2px solid #d7c9ee;
  outline: none;
}

.phone-number-title {
  color: #864bfa;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-icon-cotizacion {
  height: 195px;
}

.icon-text-cotizacion {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 28px;
}

.usd-text {
  color: #864bfa;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 8px;
}

.usd-container {
  border-radius: 100px;
  height: 40px;
  border: 3px solid var(--Purple, #864bfa);
  background-color: #e8e1ef;
  position: absolute;
  left: -18px;
  bottom: 26px;
  width: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.amount-container {
  border-radius: 100px;
  width: 240px;
  border: 3px solid var(--Purple, #864bfa);
  background-color: #7340d8;
  position: absolute;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount-text {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.amount-below {
  color: #d7c9ee;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  bottom: -5px;
  right: 50px;
}

.price-discount-container {
  position: relative;
  width: 300px;
  height: 75px;
}

.below-text {
  width: 304px;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.container-footer-cotizacion {
  cursor: pointer;
  margin-bottom: 50px;
}

.link-contact-agent {
  text-decoration: none;
  color: white;
  text-align: center;
}

.text-footer:hover {
  color: #e8e1ef;
}

@media screen and (max-width: 1000px) {
  .cotizador-mobile {
    display: flex;
    flex-direction: column;
    background-image: url("../images/background-mobile.png");
    background-position: bottom center;
    background-size: 100% 60vh;
    background-repeat: no-repeat;
    height: 105vh;
  }
  .cotizador-desktop {
    display: none;
  }

  .container-footer-cotizacion {
    margin-bottom: 0px;
    margin-top: 40px;
  }

  .text-footer {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    height: 100px;
    border-top: 0px;
  }

  .background-modal-stopper {
    width: 343px;
    height: 368px;
    background-image: url("../images/background-stopper-mobile.png");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .text-title-modal-stopper {
    font-size: 26px;
  }

  .text-description-modal-stopper-mobile {
    display: flex;
    color: var(--purple);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .text-description-modal-stopper {
    display: none;
  }

  .text-footer-modal-stopper {
    font-size: 18px;
  }
}

@media screen and (max-width: 450px) {
  .container-cotizador {
    background-image: url("../images/background-mobile.png");
    background-position: bottom center;
    background-size: 100% 60vh;
    background-repeat: no-repeat;
    height: 100%;
  }
  .text-wait {
    font-size: 24px;
  }
  .modal-success {
    padding: 0px;
    border-radius: 16px;
    border: none;
  }
  .card-contact-data-size {
    width: auto;
    height: 200px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-icon-cotizacion {
    margin-top: 30px;
  }
  .card-contact-data {
    margin: 1rem;
  }
}
